import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'components/Button';
import { PrintParticipants } from 'components/Participants/PrintSpectators';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import Export from './export';

const HeaderButtons = ({ event, date }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const {
    state: { capabilities },
  } = useContext(AppContext);

  const userType = pathname.split('/').includes('admin-employee')
    ? 'admin-employee'
    : pathname.split('/').includes('admin-track')
    ? 'admin-track'
    : 'admin';

  const isSpectatorList = pathname.includes('spectatorsList');

  const handleClickScan = (id, date) => {
    history.push(`/${userType}/scan/${id}?date=${date}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        buttonStyle={{ width: 165, marginRight: 10 }}
        onClick={
          !capabilities ||
          capabilities.length === 0 ||
          capabilities?.scan_tickets
            ? () => handleClickScan(event.id, date)
            : () => setShowPermissionModal(true)
        }
      >
        {process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'SCAN PARTICIPANTS'
          : 'SCAN TICKETS'}
      </Button>

      {isSpectatorList && (
        <>
          <Export event={event} />
          <PrintParticipants eventId={event.id} />
        </>
      )}

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={history}
      />
    </div>
  );
};

export default HeaderButtons;
